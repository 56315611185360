import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/anleitung',
    name: 'Anleitung',

    component: () => import('../views/Anleitung.vue'),
  },

  {
    path: '/daten',
    name: 'Daten',

    component: () => import('../views/datenschutz.vue'),
  },
  {
    path: '/impressum',
    name: 'Impressum',

    component: () => import('../views/impressum.vue'),
  },
  {
    path: '/info',
    name: 'Info',

    component: () => import('../views/infos.vue'),
  },
  {
    path: '/magic',
    name: 'Magic',

    component: () => import('../views/Magic.vue'),
  },

  {
    path: '/reading',
    name: 'Reading',
    props: true,

    component: () => import('../views/Reading.vue'),
  },
  {
    path: '/quellen',
    name: 'Quellen',

    component: () => import('../views/Quellen.vue'),
  },
  {
    path: '/upload2',
    name: 'Upload2',
    props: true,

    component: () => import('../views/Upload2.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
