<template>
  <div class="container justify-content-center align-items-center">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-6" id="suchfeld">
        <SayHelloHome @finished="stateManagement" id="komponent" :txt="msg"></SayHelloHome>
      </div>
      <div class="col-12">
        <h4 class="fadeInDelay" >
          finde heraus, was deine Daten über dich verraten</h4>
      </div>
      <div class="col-12">
        <button  @click="go" class="btn fadeInDelay">weiter
        </button>
      </div>
    </div>
  </div>
</template>

<script>

// eslint-disable-next-line import/extensions
import SayHelloHome from '@/components/SayHelloHome';

export default {
  name: 'Home',
  components: { SayHelloHome },
  data() {
    return {
      msg: 'mein digitales Ich',
      state: 0,
    };
  },
  methods: {
    go() {
      this.$router.push('/about');
    },
    stateManagement() {
      this.state += 1;
    },
  },
};
</script>
<style scoped>
.btn {
  color: white;
  border: 3px solid;
  border-image-slice: 1;
  border-radius: 20px;
  border-image-source: linear-gradient(90deg, rgb(213, 59, 185), rgb(32, 144, 169));
}

.btn:active, .btn:hover {
  color: #77dcee;
}

.col-12 {
  margin: 10px;
  display: flex;
  justify-content: center;
}

#suchfeld {
  margin: 3vh 0;
  /*border: 1px dotted red;*/
}

.row {
  width: 100%;
}
</style>
