<template>
  <div class="searchField">
  <h5 id='text' ref="message" class="colorfull"></h5>
    <i @click="forward" class="bi bi-search lupe"></i>
  </div>
</template>

<script>

/* eslint-disable */
export default {
  name: 'SayHello',
  data() {
    return {
      i: 0,
      speed: 100,
      alive: true,
    };
  },
  mounted() {
    this.typeWriter();
  },
  props: {
    txt: String,
  },
  methods: {
    typeWriter() {
      if (this.i < this.txt.length  && this.alive) {
        this.$refs.message.innerHTML += this.txt.charAt(this.i);
        this.i += 1;
        setTimeout(() => {
          this.typeWriter();
        }, this.speed);
      } else {
        this.$emit('finished', 'fertig');
      }
    },
    forward() {
      this.$router.push('/upload2');
    },
  },
  beforeUnmount() {
    this.alive = false;
  }
};
</script>
<style scoped>
p {
  color: white;
}
.searchField {
  background-color: #212121;
  min-width: 100%;
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.lupe {
  font-size: 18px;
}
#text {
  width: 85%;
  padding: 1vh 0 0.5vh 0;
}
</style>
