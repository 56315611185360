<template>
  <div id="nav" ref="drawer">
    <router-link class="link" @click="close" to="/">Home</router-link>
    <router-link class="link" @click="close" to="/about">Film</router-link>
    <router-link class="link" @click="close" to="/anleitung">Anleitung</router-link>
    <router-link class="link" @click="close" to="/upload2">Auswertung</router-link>
    <router-link class="link" @click="close" to="/info">Infos</router-link>
    <router-link class="link" @click="close" to="/quellen">Quellen</router-link>
    <div id="bottom">
      <router-link class="link" @click="close" to="/daten">Datenschutz</router-link>
      <router-link class="link" @click="close" to="/impressum">Impressum</router-link>
    </div>
  </div>
  <div ref="bm" id="menu" @click="toggle"></div>
  <router-view/>
</template>
<script>

import lottie from 'lottie-web/build/player/lottie';
import menuJSON from './animations/lotti/menue.json';

export default {
  name: 'Home',
  data() {
    return {
      open: false,
    };
  },
  methods: {
    toggle() {
      if (this.open) {
        this.open = false;
        this.$refs.drawer.style.width = '0';
        lottie.setDirection(-1);
        lottie.play();
      } else {
        this.open = true;
        this.$refs.drawer.style.width = '200px';
        lottie.setDirection(1);
        lottie.play();
      }
    },
    close() {
      this.open = false;
      this.$refs.drawer.style.width = '0';
      lottie.setDirection(-1);
      lottie.play();
    },
  },
  mounted() {
    lottie.loadAnimation({
      container: this.$refs.bm,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      animationData: menuJSON,
    });
  },
};
</script>
<style scoped>
#menu {
  z-index: 100;
  position: fixed;
  right: 10px;
  top: 10px;
  background: none;
  border: none;
  color: white;
  height: 40px;
  width: 40px;
  cursor: pointer;
}

#bottom {
  position: absolute;
  bottom: 5vh;
}

#nav {
  z-index: 99;
  position: fixed;
  right: 0;
  top: 0;
  text-decoration: none;
  background: #000000;
  height: 100vh;
  width: 0;
  transition: 0.5s ease-in-out;
}

#nav .link {
  font-family: IBM-Bold, sans-serif;
  color: #afafaf;
  padding: 10px 30px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

#bottom .link {
  font-family: IBM-Plex-Light, sans-serif;
}

#nav .link:hover {
  color: #ffffff;
}

#nav a.router-link-exact-active {
  color: rgb(213, 59, 185);
}

</style>

/* Global Style Properties */
<style>
* {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: white;
  /*background: black;*/
}

html {
  background: black;
}

#app {
  height: 100vh;
  margin: 0;
  padding: 0;
  background: black;
}

.container {
  /* border: 1px dotted darkred;*/
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-fluid {
}

.col, .col-12, .col-6, .col-5 {
  /*border: 1px dotted green;*/

}

.row {
  /* border: 2px dotted darkblue;*/
}

.colorfull {
  background-image: linear-gradient(90deg, rgb(213, 59, 185), rgb(32, 144, 169));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.colorfull2 {
  text-decoration: none;
  background-image: linear-gradient(90deg, rgb(139, 59, 213), rgb(69, 59, 213));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.primary {
  color: rgb(213, 59, 185);
}

@font-face {
  font-family: IBM-Bold;
  src: url('./assets/typo/IBMPlexMono-Bold.ttf');
}

@font-face {
  font-family: IBM-Medium;
  src: url('./assets/typo/IBMPlexMono-Medium.ttf');
}

@font-face {
  font-family: IBM-Regular;
  src: url('./assets/typo/IBMPlexMono-Regular.ttf');
}

@font-face {
  font-family: IBM-Light;
  src: url('./assets/typo/IBMPlexMono-Light.ttf');
}

@font-face {
  font-family: IBM-Plex-Light;
  src: url('./assets/typo/IBMPlexSans-ExtraLight.ttf');
}

@font-face {
  font-family: IBM-Plex-Regular;
  src: url('./assets/typo/IBMPlexSans-Regular.ttf');
}

h1 {
  font-family: IBM-Medium, sans-serif;
  text-align: center;
}

h2 {
  font-family: IBM-Medium, sans-serif;
  text-align: center;
}

h3 {
  font-family: IBM-Light, sans-serif;
  text-align: center;
}

h4 {
  font-family: IBM-Light, sans-serif;
  text-align: center;
}

h5 {
  font-family: IBM-Medium, sans-serif;
  text-align: center;
}

.reading {
  font-family: IBM-Regular, sans-serif;
}

.miniText {
  font-family: sans-serif;
  font-size: 16px;
}

p {
  font-family: sans-serif;
}

a {
  font-family: sans-serif;
  color: white;
  text-decoration: none;
}

a:active, a:hover {
  color: white;
  text-decoration: none;
}

.btn {
  color: white;
  border: 5px solid;
  border-image-slice: 1;
  border-radius: 20px;
  border-image-source: linear-gradient(90deg, rgb(213, 59, 185), rgb(32, 144, 169));
}

.btn:active, .btn:hover {
  color: #77dcee;
}

.wordshead {
  text-align: center;
}

.bi:hover {
  cursor: pointer;
}

.noOverflow {
  overflow: hidden;
}

.fadeIn, .fadeInDelay {
  animation-name: fadeIn;
  animation-duration: 3s;
  -webkit-animation-fill-mode: forwards;
}

.fadeInDelay {
  opacity: 0;
  animation-delay: 2s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media screen and (min-width: 1600px) {

}

@media screen and (max-width: 900px) {
}
</style>
